<template>
  <div>
    <div class="checkout-items">
      <b-card v-if="products.length === 0">
        <b-card-body
            class="bold"
        >
          Brak produktów w koszyku. Przejdź do <b-link to="/sklep">
          sklepu
        </b-link> aby wybrać produkty.
        </b-card-body>
      </b-card>
      <b-card
          v-for="product in products"
          :key="product.id"
          class="ecommerce-card"
          no-body
      >

        <!-- Product Image -->
        <div class="item-img">
          <b-link :href="`/sklep/${product.course.slug}`">
            <b-img
                :src="`${product.thumbnail}`"
                :alt="`${product.name}-${product.id}`"
            />
          </b-link>
        </div>

        <!-- Product Details: Card Body -->
        <b-card-body>
          <div class="item-name">
            <h6 class="mb-1">
              <b-link
                  class="text-body"
                  :href="`/sklep/${product.course.slug}`"
              >
                {{ product.course.title }}
              </b-link>
            </h6>
            <span class="item-company">{{ product.course.short_description }}</span>
          </div>
        </b-card-body>

      <!-- Product Options/Actions -->
      <div class="item-options text-center">
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">
             <span v-if=" product.course.price && (product.course.price).toFixed(2) !== product.total.toFixed(2)" class="text-muted">
               <del>{{ product.course.price ? (product.course.price).toFixed(2) : '' }}</del>
             </span>
                {{ product.total.toFixed(2) }} zł
              </h4>
            </div>
          </div>
          <b-button
              variant="light"
              class="mt-1 remove-wishlist"
              @click="removeProductFromCartClick(product)"
          >
            <feather-icon
                icon="XIcon"
                class="mr-50"
            />
            <span>Usuń</span>
          </b-button>
        </div>
      </b-card>
      <div v-if="upsells.length !== 0">
        <hr />
        <h3 class="mt-2 mb-2 font-bold">
          Otrzymujesz dodatkową zniżkę!
        </h3>

        <b-card
            v-for="upsell in upsells"
            :key="upsell.course.id"
            class="ecommerce-card"
            no-body
        >

          <!-- Product Image -->
          <div class="item-img">
            <b-link :href="`/sklep/${upsell.course.slug}`">
              <b-img
                  :src="`${upsell.thumbnail}`"
                  :alt="`${upsell.name}-${upsell.id}`"
              />
            </b-link>
          </div>

          <!-- Product Details: Card Body -->
          <b-card-body>
            <div class="item-name">
              <h6 class="mb-1">
                <b-link
                    class="text-body"
                    :href="`/sklep/${upsell.course.slug}`"
                >
                  {{ upsell.course.title }}
                </b-link>
              </h6>
              <span class="item-company">{{ upsell.course.short_description }}</span>
              <p class="mt-1 text-danger"><strong>Dodając teraz ten produkt do koszyka, kupujesz go z {{ upsell.discount }}% zniżką.</strong></p>
            </div>
          </b-card-body>

          <!-- Product Options/Actions -->
         <div class="item-options text-center">
            <div class="item-wrapper">
              <div class="item-cost">
                <h4 class="item-price">
                 <span v-if="(upsell.course.price).toFixed(2) !== upsell.total.toFixed(2)" class="text-muted">
                   <del>{{ (upsell.course.price).toFixed(2) }}</del>
                 </span>
                  {{ upsell.total.toFixed(2) }} zł
                </h4>
              </div>
            </div>
            <b-button
                variant="primary"
                class="mt-1"
                @click="addUpsellProductFromCartClick(upsell.course)"
            >
              <feather-icon
                  icon="ShoppingCartIcon"
                  class="mr-50"
              />
              <span>Dodaj do koszyka</span>
            </b-button>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BBadge, BButton, BCard, BCardBody, BFormSpinbutton, BImg, BLink,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { useEcommerce, useEcommerceUi } from '@/views/apps/e-commerce/useEcommerce'
import store from '@/store'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton,
  },
  setup(props, context) {
    const products = ref([])
    const upsells = ref([])
    const cart = ref({})

    const { toggleProductInWishlist, handleCartActionClick } = useEcommerceUi()
    const { removeProductFromCart } = useEcommerce()

    const removeProductFromCartClick = product => {
      removeProductFromCart(product.id)
        .then(() => {
            const productIndex = products.value.findIndex(p => p.id === product.id)
            products.value.splice(productIndex, 1)

            store.dispatch("app-ecommerce/fetchCartItems");
            context.emit('cart', 'recalculate')
          })
    }

    const addUpsellProductFromCartClick = product => {
      handleCartActionClick(product);
       setTimeout(function(){
         store.dispatch('app-ecommerce/fetchCartProducts')
             .then(response => {
               products.value = response.data.items
               upsells.value = response.data.upsells
             })
         context.emit('cart', 'recalculate')
       }, 300);
    }

    const fetchCartProducts = () => {
      store.dispatch('app-ecommerce/fetchCartProducts')
        .then(response => {
          products.value = response.data.items
          upsells.value = response.data.upsells
        })
    }
    fetchCartProducts()

    return {
      products,
      upsells,

      // UI
      toggleProductInWishlist,
      removeProductFromCartClick,
      addUpsellProductFromCartClick,
      handleCartActionClick,

      // Filter
      formatDate,
    }
  },
}
</script>
